var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{ref:"card",staticClass:"slider-slide hexagon",class:{ 'dark-theme': _vm.isDarkTheme }},[_c('div',{ref:"content",staticClass:"content"},[_c('div',{staticClass:"texts"},[(_vm.menuItemShieldStyle === 'Красный фон 1')?_c('div',{staticClass:"shield",class:{
          'red-border': _vm.menuItemShieldStyle === 'Красный фон 1',
        },domProps:{"innerHTML":_vm._s(_vm.menuItemShield)}}):_vm._e(),_vm._v(" "),_c('h2',{staticClass:"title title--h1",domProps:{"innerHTML":_vm._s(_vm.title)}}),_vm._v(" "),_c('p',{directives:[{name:"html-safe",rawName:"v-html-safe",value:(_vm.description),expression:"description"}],staticClass:"description text--basic word-breaking"}),_vm._v(" "),(
          _vm.menuItemShieldStyle === 'Красный фон 2' ||
          _vm.menuItemShieldStyle === 'Серый фон'
        )?_c('div',{staticClass:"shield",class:{
          'red-borders': _vm.menuItemShieldStyle === 'Красный фон 2',
          grey: _vm.menuItemShieldStyle === 'Серый фон',
        },domProps:{"innerHTML":_vm._s(_vm.menuItemShield)}}):_vm._e()]),_vm._v(" "),(_vm.buttonsAction && _vm.buttonsAction.length)?_c('block-buttons',{staticClass:"buttons",attrs:{"button-actions":_vm.buttonsAction}}):_vm._e()],1),_vm._v(" "),_c('f-picture',{staticClass:"image",attrs:{"source-desk":_vm.imageRectangle.src,"source-mob":_vm.image.src,"is-initial-size":false,"is-cover":true,"is-lazy":false,"sizes":_vm.sizes,"alt":_vm.image.alt}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }