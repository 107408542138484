import { render, staticRenderFns } from "./BlockHeroSlide.vue?vue&type=template&id=bc88c46c&scoped=true&"
import script from "./BlockHeroSlide.vue?vue&type=script&lang=ts&"
export * from "./BlockHeroSlide.vue?vue&type=script&lang=ts&"
import style0 from "./BlockHeroSlide.vue?vue&type=style&index=0&id=bc88c46c&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "bc88c46c",
  null
  
)

export default component.exports